$tooltip-bg-color: #ffffff;
$tooltip-offset: 13px;

.tooltip {

    left:-60px!important;

    .tooltip-inner {
        max-width: 300px;
        background-color: #ffffff;
        padding: 9px 16px;
        font-size: 15px;
        line-height: 1.2;
        border: 1px solid #E5E5E5;
        box-sizing: border-box;
        border-radius: 2px;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #4F5862;        
    }

    .arrow:before {
         border-left-color:#E5E5E5!important;
         border-right-color:transparent!important;
         border-bottom-color:#E5E5E5!important;
         border-top-color:transparent!important;
         border-width:1px!important;
         background-color:#ffffff;
         width:10px;
         height:10px;
         display:block;
         transform:rotate(-45deg);
         content:'';
         top:-5px;
         left:60px;
    }
}

.bs-tooltip-right {
    margin-left: $tooltip-offset;

    .tooltip-arrow {
        transform: translateY(-50%);

        &:before {
            border-color: #E5E5E5;
            border-width: 8px 10px 8px 0;
            background-color: #ffffff;
        }
    }
}

.bs-tooltip-left {
    margin-right: $tooltip-offset;

    .tooltip-arrow {
        transform: translateY(-50%);

        &:before {
            border-color: #E5E5E5;
            border-width: 8px 0 8px 10px;
            background-color: #ffffff;
        }
    }
}
