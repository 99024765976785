.header-menu {
    height: 75px;
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        align-items: stretch;
    }
}

.header-menu-link {
    width: 71px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    /*border-left: 1px solid $lightGrey;*/
    color: $mediumGrey;
    position: relative;
    cursor: pointer;
    @extend %animated;

    i {
        font-size: 28px;
    }

    &:hover {
        text-decoration: none;
    }

    &.header-menu-link-notif {
        color: $darkGrey;

        span {
            @include pos-a-l-t(47%, 27%);
            @include circle(16px);
            color: $white;
            font-size: 10px;
            text-align: center;
            line-height: 16px;
        }
    }
}

.header-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
    height: 100%;
    position: relative;

    @include media-breakpoint-up(md) {
        justify-content: flex-start;
    }
}

.open-mobile-search {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 200;
    background-color: #fff;
}

.header-search-form {
    display: flex;
    align-items: center;
    padding: 5px 0;
    width: auto;
    height: 100%;
    border-left: 1px solid $lightGrey;
    position: relative;
    z-index: 100;

    .open-mobile-search & {
        width: 100%;
        flex-direction: row-reverse;
    }

    @include media-breakpoint-up(md) {
        width: 100%;
        border-left: none;
    }

    i {
        margin: auto 14px;
        color: darken($searchBar, 40%);
        cursor: pointer;
        font-weight: 600;
        font-size: 1.7rem;

        @include media-breakpoint-up(md) {
            cursor: default;
        }
    }
}

.header-search-input {
    padding: 0 0 0 $container-offset-x;
    border: none;
    width: 100%;
    font-size: 1.2rem;
    font-weight: 300;
    letter-spacing: 1px;
    color: $mediumGrey;
    background:transparent;

    @include placeholder {
        color: darken($searchBar, 40%);
    }

    @include media-breakpoint-up(md) {
        padding: 0 10px;
    }

    &:focus {
        border: none;
        box-shadow: none;
        outline: none;
    }
}

.dropdown-menu-search {
    position: absolute;
    top: 100%;
    margin-top: 2px;
    left: $container-offset-x;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    right: $container-offset-x;
    background-color: #fff;
    box-shadow: $box-shadow;
    max-height: 75vh;
    overflow: auto;

    @include media-breakpoint-up(md) {
        right: 10%;
        margin-top: 4px;
    }

    .total {
        padding: 10px 33px;
        background-color: #f2f2f2;
        border-radius: 0 0 $border-radius $border-radius;
    }

    .title {
        border-bottom: 1px solid $border-color;
        padding: 12px 33px 5px;
    }

    .holder {
        padding: 11px 33px 8px;
    }

    ul {
        margin-bottom: 0;
        padding: 0;
        list-style: none;

        li {
            margin: 2px 0;
            color: $darkGrey;

            a {
                text-decoration: none;
            }
        }
    }

    .no-result {
        text-align: center;
        padding: 12px 33px;
    }
}
