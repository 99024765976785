.preloader {
    div {
        background: $lighterGrey;
        border-radius: 3px;
        height: 20px;
        margin: 10px 0;

        & + div {
            margin-top: 20px;
        }
    }
}
