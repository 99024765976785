// filters

.filter-block {

    ul {
        @include list-unstyled;
    }
}


// cards

.shop-card {
    min-height: 290px;
    margin-bottom: 30px;

    .logo-wrap {
        height: 100px;
        margin-top: 40px;
        margin-bottom: 25px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
