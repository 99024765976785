.head-content {
    padding: 20px 0px 15px;
    background-color: $body-bg;
    max-width:100%;
    margin:auto 40px;

    @include media-breakpoint-up(xxl) {
        padding: 40px 0px 15px;
    }

    .title {
        margin-bottom:30px;

        &:first-letter {
            text-transform: uppercase;
        }
    }

    .subtitle > div:not(:empty){
        font-style: normal;
        background: #FFFFFF;
        border: 1px solid #E5E5E5;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 28px 33px;
        font-weight: 300;
        font-size: 16px;
        line-height: 26px;
        color: #4F5862 !important;
    }

    .subtitle-value {
        color: #8496AB;
    }
}


.btns {
    margin-right: -20px;
    margin-top: -10px;

    .btn {
        margin-top: 10px;
        margin-right: 20px;
    }
}

.btn-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: stretch;
    margin: 0;

    @include media-breakpoint-up(sm) {
        margin: 0;
        width: auto;
        flex-direction: row;
        align-items: flex-end;
    }

    .btn-wrap-item {
        margin-top: 0px;

        @include media-breakpoint-up(sm) {
            margin-right: 20px;
        }

        &:only-child, &:last-child {
            margin-right: 0px;
        }
    }

    .btn-more {

        i {
            font-size: 32px;
            line-height: 8px;
        }
    }

    + .btn-wrap {
        margin-top:5px;
    }
}

.back-link {
    color: $mediumGrey;
    margin-right: 16px;
    margin-top: 6px;

    @include media-breakpoint-up(sm) {
        margin-right: 26px;
    }

    &:hover {
        color: $color-primary;
    }

    i {
        font-size: 28px;
    }
}

.btns-dropdown {

    .dropdown-menu {
        padding: 0;
    }
}



mrc-account {
    .main-content {
        background: #fff;
        margin: 0px 40px;
        border-radius: 8px;
        border: 1px solid #E5E5E5;
        padding-top: 25px;

        .row.mb-4 + .row {
            position: absolute;
            top: 120px;
            right: 40px;
        }
        
        .card.equal {
            padding: 0;
            border:none;
            .card-body {
                padding: 0;
            }
        }
    }
}