@use 'sass:math';

.mat-sidenav {
    width: 280px;
    position: fixed;

    &.mat-drawer {
        overflow: visible;
    }
}

.sidenav-container {
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;

    &.mat-drawer-container {
        overflow: visible;
        overflow-x: hidden;
        overflow-y: auto;

        [fullscreen].mat-drawer-opened {
            overflow: auto;

            @include media-breakpoint-down(xxl) {
                overflow: hidden;
            }
        }
    }
}

.sidenav-container {
    height: 100%;

    .mat-sidenav-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 100%;
        overflow-x: hidden;
    }
}

.sidebar {
    width: 100%;
    background: $white;
    padding: 16px 25px;
    color: $lightBlack;
    font-size: 0.85rem;
    overflow-y: auto;
    overflow-x: hidden;

    @include media-breakpoint-up(xxl) {
        padding-left: 25px;
    }
}

.logo {
    display: flex;
    justify-content: center;
    img {
        max-width: 100%;
        vertical-align: top;
        max-height: 87px;
        margin-bottom: 30px;
    }
}

.user-name {
    a {
        color: $lightBlack;
        @include transition(0.5s);

        &:hover {
            text-decoration: none;
        }
    }
}

//sidebar
.mat-sidebar {
    &.mat-drawer {
        overflow-x: hidden;
    }
}

//Sidebar menu
.sidebar-menu {
    margin: 0;
    padding: 0;
    list-style: none;

    hr {
        border: none;
    }
}

.sidebar-menu-item {
    padding: 0 0 11.5px 0;
}
.sidebar-menu-ttl {
    font-size: 0.85rem;
    margin-bottom: 15px;
    color: rgba($white, 0.5);
}

.sidebar-menu-block {
    margin: 0;
    padding: 0;
    list-style: none;
    top: -20px;
    position: relative;
}

.sidebar-menu-block-item {
    a {
        color: $lightBlack;
        font-weight: 400;
        line-height: math.div(24, 12);
        display: inline-block;
        position: relative;
        @extend %animated;

        &:hover {
            color: $lightBlack !important;
            text-decoration: none;

            i {
                opacity: 1;
            }
        }

        &.active {
            color: $lightBlack !important;
            text-decoration: none;
            background-color: $lighterBlue;

            &:before {
                content: '';
                display: block;
                /*border-left: 3px solid;*/
                height: 40px;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }

            i {
                opacity: 1;
            }
        }

        i {
            vertical-align: bottom;
            margin-right: 15px;
            font-size: 1.05rem;
            bottom: 4px;
            position: relative;
        }
    }
}

.sidebar-toggle.mat-icon-button {
    outline: none !important;
    align-self: stretch;
    align-items: center;
    height: 55px;
    background-color: $darkGrey;
    color: #fff;
    border-radius: 0;
    width: 54px;

    .mat-button-wrapper {
        display: flex;
        height: auto;
        line-height: 1;
        margin: auto;
    }

    .mat-icon {
        height: auto;
        width: auto;
        font-size: 2.5rem;
        margin: auto;
    }
}

.sidebar-close.mat-icon-button {
    position: absolute;
    left: 100%;
    top: 0;
    color: #fff;
    transition: opacity 0.25s ease;
    opacity: 0.5;
    width: 44px;
    height: 44px;

    .mat-icon {
        font-size: 1.5rem;
    }

    &:hover {
        opacity: 1;
    }
}
