@use 'sass:math';

.card {
    border-radius: 5px;
}

%heading {
    font-weight: 300;
    color: $color-heading;
}

%text-size-1 {
    font-size: 3rem;
}

%text-size-2 {
    font-size: 24px;
}

%text-size-3 {
    font-size: 1.3rem;
}

%text-size-4 {
    font-size: 1.2rem;
}

h1,
.h1 {
    font-family: Avenir;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 42px;
    color: #4f5862;
}

h2,
.h2 {
    font-family: Avenir;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 42px;
    color: #8496ab;
}

h3,
.h3 {
    font-family: Avenir;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    color: #8496ab;

    i {
        vertical-align: middle;
        color: #8496ab;
    }
}

h4,
.h4 {
    @extend %heading;
    @extend %text-size-4;
    font-weight: 400;

    i {
        vertical-align: middle;
        color: $default-text-color;
    }
}

a {
    color: $color-primary;

    &:hover {
        color: darken($color-primary, 10%);
    }
}

.link-default {
    color: $default-text-color;

    &:hover {
        color: darken($color-primary, 10%);
    }
}

.mat-sidenav-container,
.modal,
.layout-unauthorized,
.mat-dialog-container {
    .chart {
        cursor: pointer;
    }

    .btn {
        outline: none;
        @extend %animated;

        i {
            color: inherit;
        }

        &-modal {
            &:first-child {
                margin-left: auto;
                margin-right: 10px;
            }

            &:last-child {
                margin-right: auto;
                margin-left: 10px;
            }
        }

        &-primary {
            background: #fb5b5b;
            border: 1px solid #fb5b5b;
            border-radius: 2px;
            font-style: normal;
            font-weight: bold !important;
            font-size: 14px;
            line-height: 19px;
            text-align: center;
            color: #fff !important;
            padding: 5px 10px !important;
            min-height: 33px;
            margin-top: 0;
            margin-bottom: 0;

            i {
                font-size: 14px !important;
                line-height: 19px !important;
            }

            &:hover,
            &:focus {
                background: #fb5b5b;
                border: 1px solid #d53232;
                box-shadow: none !important;
            }
        }

        &.btn-secondary,
        &.btn-default {
            background: #f2f5f7;
            border: 1px solid #e5e5e5;
            box-sizing: border-box;
            border-radius: 2px;
            font-style: normal;
            font-weight: 300 !important;
            font-size: 14px;
            line-height: 14px;
            color: #8496ab;
            height: 33px;
            margin-top: 0;
            margin-bottom: 0;

            i {
                font-size: 14px !important;
                line-height: 14px !important;
            }

            &:not(:disabled):hover {
                border: 1px solid #8496ab;
                color: #8496ab !important;
            }

            &:not(:disabled):focus,
            &:not(:disabled):not(:disabled):active {
                background: #8496ab;
                border: 1px solid #8496ab;
                color: #ffffff !important;
                box-shadow: none !important;
            }
        }

        &.btn-light {
            color: $mediumGrey !important;
            border: none !important;

            @include button-variant(
                white,
                white,
                white,
                white,
                lighten($lighterGrey, 30),
                lighten($lighterGrey, 30)
            );
            box-shadow: none !important;
        }

        &.btn {
            font-weight: 400;
            padding: 0.5rem 0.8rem;
        }

        &.btn-lg {
            font-weight: 400;
            letter-spacing: 1px;
        }

        &.btn-sm {
            padding: 0.35rem 0.7rem;
            font-weight: 300;

            &.btn-ico {
                min-width: 40px;
                padding: 5px;

                i {
                    margin: 0;
                    font-size: 20px;
                    text-decoration: none;
                }
            }
        }

        &.btn-xs {
            font-size: 14px;
            font-weight: 300;
            min-width: 40px;
            padding: 0.4em 0.6em;

            span {
                vertical-align: middle;
            }

            &.btn-ico {
                i {
                    margin: 0;
                    font-size: 20px;
                    text-decoration: none;
                }
            }
        }

        &.btn-icon {
            border: none;
            background: none;
            padding: 0;
            min-width: 0;
        }

        &-link {
            display: inline-flex;
            align-items: center;

            > * + i {
                margin: 0 0 0 5px;
            }
        }

        i {
            vertical-align: text-top;
            font-size: 1.18rem;
            margin-right: 6px;
        }

        &:disabled,
        &.disabled {
            opacity: 0.5;
            cursor: not-allowed !important;
        }
    }
}

.form-control-holder {
    position: relative;

    &:before {
        @extend %material-icons;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translate(0, -50%);
        z-index: 10;
        width: 24px;
        height: 24px;
        font-size: 24px;
        line-height: 1;
    }

    &.invalid {
        .form-control {
            border-color: $color-danger;
        }

        &:before {
            content: '\e5cd';
            color: $color-danger;
        }
    }

    &.valid {
        .form-control {
            border-color: $color-success;
        }

        &:before {
            content: '\e5ca';
            color: $color-success;
        }
    }

    p {
        margin: 10px 0 0;
        line-height: math.div(17, 15);
    }
}

.input-group-append {
    position: absolute;
    right: 0px;
    top: 0px;
}

.input-group-append .input-group-text {
    z-index: 4;
    background: transparent;
    color: $darkGrey;
    padding: $input-padding-y $input-padding-x;
    border: none;
}

.input-group > .form-control:not(:last-child) {
    border-radius: $border-radius;
}

.form-control:disabled {
    border-right-color: #e9ecef;
}

.form-control {
    background-position: right 12px center;
    background-repeat: no-repeat;
    background-size: 24px 24px;

    &:focus {
        box-shadow: none;
        border-color: $mediumGrey;
        border-width: 1px;
    }

    @include placeholder {
        color: #cbcbcb;
    }

    &.ng-dirty:not(.novalidate) {
        &.ng-invalid {
            border-color: $color-danger;
            background-image: url($path-img + 'ico-validation-error.svg');
            background-position: right 15px center;
            background-size: 12px;
        }

        &.ng-valid {
            border-color: $color-success;
            background-image: url($path-img + 'ico-validation-success.svg');
            background-position: right 15px center;
            background-size: 12px;
        }
    }

    &.locked {
        background-repeat: no-repeat;
        background-size: 15px;
        background-position: right 15px center;
        background-image: url($path-img + 'lock.svg');
    }
}

textarea.form-control.locked {
    background-position: left 13px top 11px;
}

@include form-control-size(
    '',
    $form-control-height,
    $form-control-size,
    $form-control-line-height,
    $form-control-offset-x
);
@include form-control-size(
    '-sm',
    $form-control-sm-height,
    $form-control-sm-size,
    $form-control-sm-line-height,
    $form-control-sm-offset-x
);
@include form-control-size(
    '-xs',
    $form-control-xs-height,
    $form-control-xs-size,
    $form-control-xs-line-height,
    $form-control-xs-offset-x
);

@include form-label-size(
    '',
    $form-control-height,
    $form-label-size,
    $form-label-line-height
);
@include form-label-size(
    '-sm',
    $form-control-sm-height,
    $form-label-sm-size,
    $form-label-sm-line-height
);
@include form-label-size(
    '-xs',
    $form-control-xs-height,
    $form-label-xs-size,
    $form-label-xs-line-height
);

.form-control-bg,
.form-control-bg[readonly],
.form-control-bg[readonly]:focus {
    color: #4f5862;
    border: 1pt solid #e5e5e5;
    background-color: #fff;
    border-radius: 2px;
}

.form-control-bg:focus {
    border: 1px solid #8496ab;
    background-color: #fff;
}

.form-control-plaintext,
.form-control-plaintext:focus {
    background-color: transparent !important;
    border-color: transparent;
}

label {
    color: $color-dark;
    font-size: 15px;
    font-weight: 400;

    .mat-radio-label-content {
        font-weight: 400;
    }
}

dl {
    margin-bottom: 0.75rem;

    dt,
    dd {
        display: inline-block;
        margin-bottom: 0;
    }

    dt {
        display: inline-block;
        font-weight: 300;
        color: $darkBlue;
        word-wrap: normal;
        font-size: 1rem;
        letter-spacing: 1px;
        margin-right: 0.75rem;
    }

    dd {
        font-size: 0.9rem;
        font-weight: 300;
        color: $mediumGrey;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &:first-child {
        margin-top: 0.4rem;
    }
}

//Modals
%modal-box {
    padding: 12px 20px;

    @include media-breakpoint-up(sm) {
        padding: 15px 40px;
    }
}

.modal {
    z-index: 999;

    .form-group {
        margin-top: 0;
    }
}

.modal-backdrop {
    z-index: 998;
}

.modal-content {
    padding-top: 0;
    padding-bottom: 0;
    position: relative;

    @include media-breakpoint-down(sm) {
        padding: 20px 0;
    }
}

.modal-header {
    @extend %modal-box;
    border-bottom: 0;
    padding: 15px 20px;

    .close {
        padding: 0;
        font-size: 32px;
        color: #b2b2b2;
        position: absolute;
        right: 28px;
        top: 22px;
        line-height: 1;

        &:hover {
            color: $dark;
        }

        span {
            font-size: inherit;
            line-height: 1;
        }
    }
}

.modal-body {
    @extend %modal-box;
    padding: 45px 45px 20px 45px !important;
    padding-top: 10px !important;
    .close-wrap {
        width: calc(100% + 40px);
    }
    .text-holder {
        margin: 2em 0 2em 0;
    }
    .title {
        font-family: Avenir;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 42px;
        text-align: center;
        color: #4f5862;
    }
    img {
        filter: brightness(60%);
        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        -ms-filter: grayscale(100%);
        -o-filter: grayscale(100%);
        filter: gray;
        opacity: 0.5;
        margin-bottom: 40px;
        margin-top: 20px;
    }
    .subtitle {
        font-family: Avenir;
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        color: #4f5862;
        margin-bottom: 20px;
    }
}

.modal-footer {
    @extend %modal-box;
    border-top: none;
    padding: 10px 45px 45px 45px !important;
}

.modal-title {
    margin-right: 30px;
}

.modal-body {
    .upload-block {
        text-decoration: none;
        width: 100%;
    }
}

.tooltip-top {
    transform: translate(-10px, 0);
}

input[type='number'] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.error-wrap {
    text-align: center;
    padding: 70px 0;
}

.text-progress {
    color: $color-progress !important;
}

.text-medium {
    font-weight: 400 !important;
}

.text-big {
    font-size: 1.2em;
    line-height: 1.5;
}

.auth-error {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-width-800 {
    max-width: 800px;
}

.modal-title-center {
    .modal-header {
        justify-content: center;
    }
}

.modal-footer-right {
    .modal-footer {
        justify-content: flex-end !important;
    }
}

.modal-content > .modal-body > h3 {
    padding-top: 40px;
}
