.snackbar {
    background-color: #dedede !important;
    min-width: 160px !important;
    margin-left: 5px;

    i {
        float: left;
        padding-top: 2px;
    }
}

#offerCompareModal {
    .modal-content {
        width: 960px
    }

    .header {
        text-align: center;
    }

    .subheader {
        font-size: 12px;
        text-align: center;
    }

    .row-label {
        font-size: 12px;
        text-align: left;
    }

    .divider {
        background-color: #eeeeee;
        margin: 30px -40px;
        padding: 10px 40px 5px;
    }

    .see-more {

    }

    @media (min-width: 576px){
        .modal-dialog {
            max-width: 960px;
        }

    }
}

#offer-comparison-modal-body {
    .vertiflex {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
}
