.user {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 10px;

    .user-avatar {
        width: 50px;
        height: 50px;
        border-radius: 4px;
        overflow: hidden;
        position: relative;
        margin-right: 10px;

        .no-avatar {
            width: 50px;
            height: 50px;
            display: flex;
            align-content: center;
            justify-content: center;
            color: $white;
            background: $lighterGrey;

            i {
                font-size: 35px;
                line-height: inherit;
            }
        }

        .avatar {
            width: 50px;
            height: 50px;
            display: flex;
            align-content: center;
            justify-content: center;

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .user-info-ttl {
        color: $darkGrey;
        font-size: 17px;
        font-weight: 500;
    }

    .user-info-txt {
        font-size: 14px;
    }
}

.product-image {

     img {
         max-width: 100%;
     }
}

.product-name {
    text-align: center;
    font-size: 17px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 30px;
    color: $darkGrey;
}

.status-block {
    text-align: center;
    font-size: 15px;

    h3 {
        color: $mediumGrey;
        margin-top: 20px;
    }
}
