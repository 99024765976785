@use 'sass:math';

.wrapper {
    overflow-y: auto !important;
    -webkit-overflow-scrolling: touch !important;
}

//Container
$container-offset-x: 15px;

//Bootstrap
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1680px,
);

$border-color: rgba(0, 0, 0, 0.125);
$input-padding-x: 0.375rem;
$input-padding-y: 0.5rem;
$modal-offset-x: 40px;
$modal-md: 570px;
$modal-content-border-radius: 5px;
$modal-header-padding: 15px $modal-offset-x;
$modal-inner-padding: 15px $modal-offset-x;
$modal-dialog-margin: $container-offset-x;

//Path
$path-img: '/assets/images/';

//Fonts
$ubuntu: 'Ubuntu', sans-serif;
$avenir: 'Avenir';

//Colors
$black: #000;
/*$lightBlack: #272e3b;*/
$darkGrey: #5c6370;
$mediumGrey: #8c98a7;
/*$lightGrey: #D4DDE6;*/
$lighterGrey: #edf1f5;
$white: #fff;
$darkBlue: #1f2734;
$lightBlue: #aeb8c6;

//New Colors
$main-color: #fb5b5b;
$lightBlack: #4f5862;
$lighterBlue: #f2f5f7;
$mediumBlue: #8496ab;
$lightGrey: #e5e5e5;

$lighterGrey: #f1f1f1;
$greyBlue: #667a82;
$greyBlueLight: #b3cdce;
$darkGreen: #008700;

$searchBar: #dadcdf;

$primary: #fb5b5b !default;
$danger: #fb5b5b;
$secondary: $mediumGrey;
$color-primary: #fb5b5b !default;
$color-success: #28a745;
$color-danger: #fb5b5b;
$color-dark: $darkGrey;
$color-progress: #4d91df;

$color-heading: $mediumGrey;

//Default settings
$default-font: $avenir;
$default-font-size: 11pt;
$default-line-height: 1.5;
$default-font-weight: 300;
$default-font-style: normal;
$default-text-color: $darkGrey;
$textColorInverse: $white;
$body-bg: #f2f5f7;

$bg: #fff;

$theme-colors: (
    'progress': $color-progress,
);

$default-anmation: 0.3s ease;

%animated {
    transition: all $default-anmation;
}

$box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.12);

//Form elements
$form-group-offset-y: 8pt;

$form-control-height: 40px;
$form-control-size: 15px;
$form-control-line-height: 2;
$form-control-offset-x: 15px;

$form-control-sm-height: 35px;
$form-control-sm-size: 14px;
$form-control-sm-line-height: 2;
$form-control-sm-offset-x: 12px;

$form-control-xs-height: 28px;
$form-control-xs-size: 14px;
$form-control-xs-line-height: 2;
$form-control-xs-offset-x: 7px;

$form-label-size: 16px;
$form-label-line-height: 1;

$form-label-sm-size: 15px;
$form-label-sm-line-height: 1;

$form-label-xs-size: 15px;
$form-label-xs-line-height: 1;

$radio-btn-size: 16px;

//switcher
$switcher-track-width: 64px;
// $switcher-track-height: $switcher-track-width/2;
// $switcher-track-radius: $switcher-track-height/2;
$switcher-track-height: math.div($switcher-track-width, 2);
$switcher-track-radius: math.div($switcher-track-height, 2);
$switcher-track-bg: $default-text-color;
$switcher-track-bg-active: $color-primary;
$switcher-track-offset: 3px;

// $swither-toggle-width: $switcher-track-width/2 - 2 * $switcher-track-offset;
$swither-toggle-width: math.div($switcher-track-width, 2) - 2 *
    $switcher-track-offset;
$swither-toggle-height: $switcher-track-height - 2 * $switcher-track-offset;
$swither-toggle-bg: $lightGrey;

//sort
$sort-width: 7.7px;
$sort-height: 4px;
$sort-color: $default-text-color;
$sort-color-active: $darkGrey;

//table
$table-border-color: #dadcdf;
$table-cell-offset-x: 20px;
$table-cell-offset-y: 15px;
$table-row-color-hover: #edf1f5;
$table-row-even-bg: #f6f8fa;

//Tooltip
$form-tooltip-bg: rgba($color-dark, 0.75);
$form-tooltip-arr-size: 8px;
$form-tooltip-offset: 20px;

//pagination
$pagination-cell-size: 38px;
$pagination-cell-radius: 3px;
$pagination-cell-color: $default-text-color;
$pagination-cell-color-active: $color-dark;

//Content
$content-offset-x: 40px;
$content-offset-x-sm: 15px;

$head-content-offset-x: $content-offset-x;
$head-content-offset-x-sm: $content-offset-x-sm;
$head-content-offset-y: 30px;
$head-content-offset-y-sm: 30px;
$head-content-bg: #fff;

$main-content-offset-x: $content-offset-x;
$main-content-offset-x-sm: $content-offset-x-sm;
$main-content-offset-y: 25px;
$main-content-offset-y-sm: 30px;

//Colorpicker
$colorpicker-item-size: 16px;
$colorpicker-menu-padding-x: 20px;
$colorpicker-menu-padding-y: 25px;
$colorpicker-menu-offset-top: -1 *
    ($colorpicker-item-size + $colorpicker-menu-padding-y);
$colorpicker-menu-offset-right: -$colorpicker-menu-padding-x;

// Close
$close-font-size: 1.5rem;
$close-font-weight: 400;
$close-color: $danger;
$close-text-shadow: none;

@mixin user-selection-none {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non */
}
