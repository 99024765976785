@use 'sass:math';

// Includes font $font-face.
@mixin font-face($name, $file-name, $font-weight: 400, $font-style: normal) {
    @font-face {
        font-family: $name;
        src: url('#{$path-fonts}#{$file-name}.woff2') format('woff2'),
            url('#{$path-fonts}#{$file-name}.woff') format('woff');
        font-weight: $font-weight;
        font-style: $font-style;
    }
}

/* Position */

@mixin pos-l-t($l: 0, $t: 0) {
    left: $l;
    top: $t;
}

@mixin pos-l-b($l: 0, $b: 0) {
    left: $l;
    bottom: $b;
}

@mixin pos-r-t($r: 0, $t: 0) {
    right: $r;
    top: $t;
}

@mixin pos-r-b($r: 0, $b: 0) {
    right: $r;
    bottom: $b;
}

@mixin pos-a-l-t($l: 0, $t: 0) {
    position: absolute;
    @include pos-l-t($l, $t);
}

@mixin pos-a-l-b($l: 0, $b: 0) {
    position: absolute;
    @include pos-l-b($l, $b);
}

@mixin pos-a-r-t($r: 0, $t: 0) {
    position: absolute;
    @include pos-r-t($r, $t);
}

@mixin pos-a-r-b($r: 0, $b: 0) {
    position: absolute;
    @include pos-r-b($r, $b);
}

@mixin pos-r-l-t($l: 0, $t: 0) {
    position: relative;
    @include pos-l-t($l, $t);
}

@mixin pos-r-l-b($l: 0, $b: 0) {
    position: relative;
    @include pos-l-b($l, $b);
}

@mixin pos-r-r-t($r: 0, $t: 0) {
    position: relative;
    @include pos-r-t($r, $t);
}

@mixin pos-r-r-b($r: 0, $b: 0) {
    position: relative;
    @include pos-r-b($r, $b);
}

@mixin custom-row($offset) {
    margin-left: math.div(-$offset, 2);
    margin-right: math.div(-$offset, 2);

    & > * {
        padding-left: math.div(-$offset, 2);
        padding-right: math.div(-$offset, 2);
    }
}

@mixin arrow($width: 20px, $height: $width, $direction: up, $color: $white) {
    @extend %arr;

    // Right
    @if $direction == right {
        border-top: $height solid transparent !important;
        border-bottom: $height solid transparent !important;
        border-left: $width solid $color;
    }

    // Left
    @if $direction == left {
        border-top: $height solid transparent !important;
        border-bottom: $height solid transparent !important;
        border-right: $width solid $color;
    }

    // Up
    @if $direction == up {
        border-left: $width solid transparent !important;
        border-right: $width solid transparent !important;
        border-bottom: $height solid $color;
    }

    // Down
    @if $direction == down {
        border-left: $width solid transparent !important;
        border-right: $width solid transparent !important;
        border-top: $height solid $color;
    }
}

/* Clear offset */
%clear-offset {
    margin: 0;
    padding: 0;
}

/* Clear list */
%clear-list {
    list-style: none;
    @extend %clear-offset;
}

/* Clear button */
%clear-button {
    outline: none;
    border: none;
    padding: 0;
    margin: 0;
    width: auto;
}

/* Generate rows */
@mixin custom-row($offset) {
    margin-left: math.div(-$offset, 2);
    margin-right: math.div(-$offset, 2);

    & > * {
        padding-left: math.div(-$offset, 2);
        padding-right: math.div(-$offset, 2);
    }
}

@mixin clear-row() {
    & > * {
        width: 100%;
    }
}

%font-awesome {
    display: inline-block;
    font-family: FontAwesome;
    font-size: inherit;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@function divide($divisor, $dividend, $unit: '') {
    $r: math.div($divisor, $dividend);

    @if $unit {
        @return $r#{$unit};
    } @else {
        @return $r;
    }
}

@mixin circle($size) {
    width: $size;
    height: $size;
    border-radius: 50%;
}

%absCenterY {
    top: 50%;
    transform: translateY(-50%);
}

%absCenterX {
    left: 50%;
    transform: translateX(-50%);
}

%absCenter {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

%bgCover {
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

%material-icons {
    font-family: 'Material Icons';
    text-rendering: optimizeLegibility;
    font-feature-settings: 'liga' 1;
    font-style: normal;
    text-transform: none;
    line-height: 1;
    font-size: 24px;
    width: 1em;
    height: 1em;
    display: inline-block;
    overflow: hidden;
    -webkit-font-smoothing: antialiased;
}

@function divide($divisor, $dividend, $unit: '') {
    $r: math.div($divisor, $dividend);

    @if $unit {
        @return $r#{$unit};
    } @else {
        @return $r;
    }
}

@mixin reduce-font-size($size) {
    $k: 0.85;

    @media (min-width: $screen-xl) and (max-width: $screen-xxxxl) {
        font-size: round($size * $k);
    }
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
}

@function em($pixels, $context: $default-font-size) {
    @if (unitless($pixels)) {
        $pixels: $pixels * 1px;
    }

    @if (unitless($context)) {
        $context: $context * 1px;
    }
    @return math.div($pixels, $context) * 1em;
}

@mixin form-control-size(
    $size,
    $height,
    $font-size,
    $line-height: 1,
    $offset-x: 20px
) {
    $offset-y: math.div($height - $font-size * $line-height, 2);

    .form-control#{$size} {
        height: $height;
        min-height: $height;
        line-height: $line-height;
        font-size: $font-size;
        padding: $offset-y ($offset-x + 15px) $offset-y $offset-x;
    }
}

@mixin form-label-size($size, $height, $font-size, $line-height: 1) {
    $offset-y: math.div($height - $font-size * $line-height, 2);

    .col-form-label#{$size} {
        line-height: $line-height;
        font-size: $font-size;
        padding-top: $offset-y;
        padding-bottom: $offset-y;
    }

    .form-label#{$size} {
        line-height: $line-height;
        font-size: $font-size;
    }
}

/* Generate rows */
@mixin custom-row($offset) {
    margin-left: math.div(-$offset, 2);
    margin-right: math.div(-$offset, 2);

    & > * {
        padding-left: math.div(-$offset, 2);
        padding-right: math.div(-$offset, 2);
    }
}

%flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

%full-width {
    width: 100%;
    height: 100%;
}

%clear-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

@mixin even() {
    &:nth-child(even) {
        @content;
    }
}

@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}
