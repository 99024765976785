.bs-datepicker {
    border-radius: 5px;
    overflow: hidden;
}

.bs-datepicker-container {
    padding: 0;

    .bs-datepicker-head {
        background-color: transparent;
        height: 40px;

        button {
            color: $mediumGrey;
        }
    }

    .bs-datepicker-body {
        border: none;

        .years {

            td {

                &.is-highlighted {

                    &:before {
                        background-color: transparent !important;
                    }

                    span {
                        background-color: transparent !important;
                        border: 1px solid;
                    }
                }

                span {
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                    margin: 9px auto 11px;
                }
            }
        }

        .months {
            td {

                &.is-highlighted {

                    &:before {
                        background-color: transparent !important;
                    }
                }

                span {
                    border: 1px solid transparent;
                }
            }
        }

        .days {

            td {

                &:first-child {

                    .in-range {

                        &:before {
                            border-radius: 12px 0 0 12px;
                            left: 3px;
                        }
                    }
                }

                &:last-child {

                    .in-range {

                        &:before {
                            border-radius: 0 12px 12px 0;
                            right: 3px;
                        }
                    }

                    .select-start {

                        &:before {
                            display: none;
                        }
                    }

                    .select-end {

                        &:before {
                            border-radius: 0;
                        }
                    }
                }
            }

            span {

                &:after {
                    content: '';
                    width: 25px;
                    height: 25px;
                    line-height: 25px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    border-radius: 50%;
                    z-index: -1;
                }

                &:before {
                    left: -3px;
                    top: 3px;
                    bottom: 3px;
                }

                &.select-start {
                    background-color: $primary;

                    &:before {
                        left: 50%;
                    }

                    &.select-end {

                        &:before {
                            display: none;
                        }
                    }
                }

                &.select-end {

                    &:before {
                        left: -3px !important;
                        right: 50% !important;
                    }
                }

                &.in-range {
                    color: $white;
                }

                &.is-highlighted {

                    &.in-range {

                        &:before {
                            border-radius: 0 12px 12px 0;
                            right: 3px;
                            left: -3px !important;
                        }
                    }

                    &:not(.in-range) {

                        &:before {
                            border-radius: 50%;
                            right: 3px;
                            left: 3px;
                        }
                    }
                }
            }
        }
    }
}

.bs-datepicker-container {

    .bs-calendar-container {

        & > div {

            @include media-breakpoint-up(sm) {
                display: flex;
            }
        }
    }
}

.datepicker {

    bs-daterangepicker-container {
        left: auto !important;
        top: 40px !important;
        right: 0;

        @include media-breakpoint-down(md) {
            left: 0 !important;
            right: auto;
        }
    }

    &.datepicker-range {

    }
}

.datepicker-wrapper {
    position: relative;

    bs-daterangepicker-container {
        left: auto !important;
        top: 0 !important;
        transform: translateX(0);
        right: 0;
    }
}

%datepicker-arr {
    width: 8px;
    height: 8px;
    content: '';
    display: block;
    border: 2px solid $secondary;
    transform: rotate(45deg);
}

bs-datepicker-navigation-view {
    display: flex;
    align-items: center;
    justify-content: center;

    .previous {
        position: absolute;
        left: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            display: none !important;
        }

        &:before {
            @extend %datepicker-arr;
            border-right: none;
            border-top: none;
            margin-left: 3px;
        }
    }

    .next {
        position: absolute;
        right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            display: none !important;
        }

        &:before {
            @extend %datepicker-arr;
            border-left: none;
            border-bottom: none;
            margin-right: 3px;

        }
    }

    .current {
        font-size: 14px;
    }
}
