@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://www.google.com/design/spec/style/color.html
$candy-app-primary: mat-palette($mat-red);
$candy-app-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$candy-app-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$candy-app-theme: mat-light-theme(
    $candy-app-primary,
    $candy-app-accent,
    $candy-app-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($candy-app-theme);

@import './_variables.scss';

$custom-typography: mat-typography-config(
    $font-family: $default-font,
    $headline: mat-typography-level(32px, 48px, 400),
    $body-1: mat-typography-level(16px, 24px, 300),
);
@include angular-material-typography($custom-typography);

/*.mrc-table, mrc-table {*/
.mat-tab-header {
    border: none;

    .mat-ink-bar {
        height: 4px;
        background: none !important;
    }

    .mat-tab-labels {
        display: table;
        width: 100%;
        background: #fff;
        border-radius: 8px;

        .mat-tab-label {
            border-top: 1px solid #e5e5e5 !important;
            border-bottom: 1px solid #e5e5e5 !important;
            border-left: 1px solid #e5e5e5 !important;
            border-right: none !important;
            height: 34px;
            display: table-cell;
            padding: 7px 0;

            &:first-child {
                border-radius: 8px 0 0 8px;
            }

            &:last-child {
                border-right: 1px solid #e5e5e5 !important;
                border-radius: 0 8px 8px 0;
            }

            &:only-child {
                border-radius: 8px !important;
            }

            .mat-ripple-element {
                display: none !important;
            }

            &.mat-tab-label-active {
                background-color: #fb5b5b;
                color: #ffffff;
                opacity: 1;
                border: 1px solid #fb5b5b;

                &:hover {
                    background-color: #fb5b5b;
                    color: #ffffff;
                    border: 1px solid #fb5b5b;
                }
            }

            &:hover {
                background-color: #f2f5f7;
                color: #4f5862;
            }

            &:focus {
            }

            .badge-secondary {
                border-radius: 13px;
                height: 27px;
                min-width: 27px;
                line-height: 16px;
                padding: 5px;
                text-align: center;
                color: #333;
                background-color: $lightGrey;
                font-size: 0.85em;
                font-weight: 400;
                margin-left: 7px;
            }
        }

        .mat-tab-label-content {
            text-transform: none;
            font-size: 0.8rem;
        }
    }
}
/*}*/

.mat-tab-nav-bar,
.mat-tab-header {
    border: none;
}
