// Workaround
// @swimlane/ngx-datatable is deprecated & should be replaced
@use '../node_modules/@swimlane/ngx-datatable/index.css';
@use '../node_modules/@swimlane/ngx-datatable/themes/bootstrap.css';
@use '../node_modules/@swimlane/ngx-datatable/assets/icons.css';

@font-face {
    font-family: 'Avenir';
    src: url('./assets/fonts/Avenirltstd-light.otf') format('truetype');
    font-weight: 100;
}
@font-face {
    font-family: 'Avenir';
    src: url('./assets/fonts/AvenirLTStd-Book.otf') format('truetype');
    font-weight: 300;
}
@font-face {
    font-family: 'Avenir';
    src: url('./assets/fonts/AvenirLTStd-Roman.otf') format('truetype');
    font-weight: 400;
}
@font-face {
    font-family: 'Avenir';
    src: url('./assets/fonts/AvenirLTStd-Medium.otf') format('truetype');
    font-weight: 500;
}

//light
@font-face {
    font-family: 'Font Awesome 5 Pro';
    font-style: normal;
    font-weight: 300;
    font-display: block;
    src: url('./assets/fonts/font-awesome/fa-light-300.eot');
    src: url('./assets/fonts/font-awesome/fa-light-300.eot?#iefix')
            format('embedded-opentype'),
        url('./assets/fonts/font-awesome/fa-light-300.woff2') format('woff2'),
        url('./assets/fonts/font-awesome/fa-light-300.woff') format('woff'),
        url('./assets/fonts/font-awesome/fa-light-300.ttf') format('truetype'),
        url('./assets/fonts/font-awesome/fa-light-300.svg#fontawesome')
            format('svg');
}

.fal {
    font-family: 'Font Awesome 5 Pro';
    font-weight: 300;
}

//font awesome reference
//regular
@font-face {
    font-family: 'Font Awesome 5 Pro';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url('./assets/fonts/font-awesome/fa-regular-400.eot');
    src: url('./assets/fonts/font-awesome/fa-regular-400.eot?#iefix')
            format('embedded-opentype'),
        url('./assets/fonts/font-awesome/fa-regular-400.woff2') format('woff2'),
        url('./assets/fonts/font-awesome/fa-regular-400.woff') format('woff'),
        url('./assets/fonts/font-awesome/fa-regular-400.ttf') format('truetype'),
        url('./assets/fonts/font-awesome/fa-regular-400.svg#fontawesome')
            format('svg');
}

.far,
.fa {
    font-family: 'Font Awesome 5 Pro';
    font-weight: 400;
}

//solid
@font-face {
    font-family: 'Font Awesome 5 Pro';
    font-style: normal;
    font-weight: 900;
    font-display: block;
    src: url('./assets/fonts/font-awesome/fa-solid-900.eot');
    src: url('./assets/fonts/font-awesome/fa-solid-900.eot?#iefix')
            format('embedded-opentype'),
        url('./assets/fonts/font-awesome/fa-solid-900.woff2') format('woff2'),
        url('./assets/fonts/font-awesome/fa-solid-900.woff') format('woff'),
        url('./assets/fonts/font-awesome/fa-solid-900.ttf') format('truetype'),
        url('./assets/fonts/font-awesome/fa-solid-900.svg#fontawesome')
            format('svg');
}
.fas {
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900;
}
//end font awesome reference

@import './styles/variables';
@import 'bootstrap/scss/bootstrap-reboot';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/alert';
@import 'bootstrap/scss/modal';
@import 'bootstrap/scss/badge';
@import 'bootstrap/scss/card';
@import 'bootstrap/scss/buttons';
@import 'bootstrap/scss/close';
@import 'bootstrap/scss/dropdown';
@import 'bootstrap/scss/tooltip';
@import 'bootstrap/scss/forms';
@import 'bootstrap/scss/button-group';
@import 'bootstrap/scss/input-group';
@import 'bootstrap/scss/pagination';
@import 'bootstrap/scss/media';
@import 'bootstrap/scss/nav';
@import 'bootstrap/scss/navbar';
@import 'bootstrap/scss/utilities';
@import '@ng-select/ng-select/themes/default.theme.css';
@import './styles/placeholder-loading';
@import 'ngx-toastr/toastr-bs4-alert';
@import 'ngx-bootstrap/datepicker/bs-datepicker.css';
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.bubble.css';
@import 'quill/dist/quill.snow.css';
@import './styles/mixins';
@import './styles/animations';
@import './styles/close';
@import './styles/print';
@import './styles/colorpicker';
@import './styles/dropdown';
@import './styles/head-content';
@import './styles/header';
@import './styles/image-uploader';
@import './styles/layout';
@import './styles/bootstrap-customize';
@import './styles/form-elements';
@import './styles/tooltips';
@import './styles/materials';
@import './styles/pagination';
@import './styles/tables';
@import './styles/preloader';
@import './styles/list-table';
@import './styles/sidebar';
@import './styles/datepicker';
@import './styles/card';
@import './styles/export-contract';
@import './styles/pages/add-portal';
@import './styles/pages/contract-form';
@import './styles/pages/order-details';
@import './styles/pages/dashboard';
@import './styles/pages/edit-supplier';
@import './styles/pages/shops';
@import './styles/pages/404';
@import './styles/pages/contract-form';
@import './styles/pages/offers';
@import './styles/pages/users';
@import './styles/pages/document';
@import './styles/font-awesome';
