.image-uploader {
    position: relative;

    .upload-block {
        position: relative;
        overflow: hidden;
        cursor: pointer;

        &:hover {
            color: $primary;
        }

        input[type=file] {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            font-size: 0;
            opacity: 0;
            cursor: pointer;

            &::-webkit-file-upload-button {
                cursor: pointer;
            }
        }
    }

    .uploaded-block {
        position: relative;

        .close {
            position: absolute;
            right: 0;
            top: 0;
            font-size: 24px;
            z-index: 2;
            background-color: rgba(#fff, .5);
        }

        img {
            width: auto;
            height: auto;
            display: block;
            max-width: 100%;
            max-height: 400px;
            margin: auto;
        }
    }
}