.portal-created-info {
    padding: 15px;

    @include media-breakpoint-up(lg) {
        padding: 50px 30px;
    }

    .image {
        margin-bottom: 50px;

        img {
            max-width: 100%;
        }
    }

    .description {
        margin: 1.5em 0;
    }

    .list-table {
        width: auto;
    }
}

.modal-with-backdrop {
    background-color: rgba(0, 0, 0, .5);
}

.modal-delete-confirmation {

    .modal-content {

        @include media-breakpoint-up(sm) {
            padding: 43px 0 75px;
        }
    }

    img {
        display: block;
        margin: 0 auto 29px;
    }

    .modal-title {
        margin: 0 0 5px;
    }

    p {
        font-size: 1.2em;
        line-height: 1.5;
        margin: 0 0 23px;
    }
}

.flat-rate-message {
    color: $primary;
    padding: 5px 15px 0;
    font-size: 12px;
}
