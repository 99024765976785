.dropdown-menu {
    box-shadow: $box-shadow;
    border: none;
    border-radius: $border-radius;

    &.dropdown-filter {
        width: 290px;

        .form-group {
            margin: 0 0 25px;
        }

        .btn-sm {
            min-width: 115px;
        }
    }
}

.dropdown-menu-body {
    padding: 15px;
}
