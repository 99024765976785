body {
    height: 100%;
    position: relative;
    font-size: $default-font-size;
    font-family: $default-font;
    font-weight: $default-font-weight;
    color: $default-text-color;
    background-color: $body-bg;
    min-width: 320px;

    @include media-breakpoint-down(md) {
        font-size: 14px;
    }
}

b, strong {
    font-weight: bold;
}

.mat-drawer-container {
    color: inherit !important;
    background-color: transparent !important;
}

//rows


.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

$colors: (
    'success': $color-success,
    'danger': $color-danger,
    'primary': $color-primary,
    'warning': #b51c2c,
    'info': #0063db,
    'progress': $color-progress
);


@each $key, $color in $colors {

    .color-#{$key} {
        color: $color !important;
    }

    .bg-#{$key} {
        background-color: $color !important;
    }
}

$row-sizes: (18, 20, 30, 40, 50, 60, 80);

@each $row in $row-sizes {

    .row-#{$row} {
        @include custom-row($row*1px);
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

            .row#{$infix}-#{$row} {
                @include custom-row($row*1px);
            }
        }
    }
}

.mw-0 {
    min-width: 0 !important;
}

.fade {
    transition: opacity .25s ease;
}

.container {
    max-width: 1200px;
}

.link-inherit {
    color: inherit;

    &:hover {
        color: $color-primary;
    }
}

a.light {
    text-decoration: underline;
}

.layout-unauthorized {

    .logo {
        margin: 20px auto 48px;
        text-align: center;

        img {
            vertical-align: top;
            max-width: 100%;
            max-height: 87px;
        }
    }

    .links {
        font-size: 0.9rem;
    }

    .title {
        margin: 13px 0 9px;
        text-align: center;

        &.title-sm {
            font-size: em(24);
        }
    }

    .title-description {
        font-size: em(18);
        text-align: center;
        color: $mediumGrey;
        margin-bottom: 0;
    }

    .links {
        margin: 42px 0 20px;
    }
}

.main-content {
    padding: $main-content-offset-y-sm*0.5 $main-content-offset-x-sm $main-content-offset-y-sm $main-content-offset-x-sm;
    padding-top:0;

    @include media-breakpoint-up(xxl) {
        padding: $main-content-offset-y $main-content-offset-x;
        padding-top:0;
    }
}

button:focus {
    outline: 0px !important;
}
