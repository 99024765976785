.page-404 {
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: -1;

    h1 {
        font-size: 40px;
    }

    p {
        font-size: 30px;
    }
}

.page-404-wrapper {
    height: 100vh;
}
