.dashboard-ttl {
    font-size: 24px;
    font-weight: 400;
}

.close-wrap {
    display: flex;
    justify-content: flex-end;

    .close {
        font-weight: normal;
        font-size: 25px;
        line-height: 25px;
        letter-spacing: 0.35em;
        color: #8496AB;
    }
}

.modal-form {

    &.modal-form-source {
        padding: 30px 40px;

        @include media-breakpoint-down(sm) {
            padding: 20px;
        }

        @include media-breakpoint-down(xs) {
            padding: 10px 20px;
        }

        .label-source {
            position: relative;
            transition: .5s all ease;

            .source-radio {
                position: absolute;
                left: -30px;
                top: -2px;
                color: $lightGrey;
                display: none;

                &:before {
                    content: '';
                    position: absolute;
                    top: 3px;
                    left: 3px;
                    background: $primary;
                    width: 18px;
                    height: 18px;
                    z-index: 0;
                    border-radius: 50%;
                }

                i {
                    position: relative;
                    z-index: 10;
                }
            }

            input[type=radio] {

                &:checked {

                    & + .source-radio {
                        display: block;
                    }

                    & ~ .source-name {
                        font-weight: 600;
                    }
                }
            }
        }


    }

    &.modal-form-style {
        padding: 30px 45px;

        @include media-breakpoint-down(sm) {
            padding: 20px;
        }

        @include media-breakpoint-down(xs) {
            padding: 10px 0;
        }
    }
}

.modal {

    .close {

        i {
            font-size: 32px;
        }
    }
}

.modal-md {
    max-width: 600px;
}

.modal-sm {
    max-width: 500px;
}

.modal-xs {
    max-width: 300px;
}

.chart {
    width: 170px;
    height: 105px;
    background: $lighterGrey;
    border-radius: 5px;
    border: 1px solid $lightGrey;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    @include media-breakpoint-down(xs) {
        width: 120px;
        height: 80px;
    }

    .chart-icon {
        font-size: 80px;
        color: $lightGrey;

        @include media-breakpoint-down(xs) {
            font-size: 50px;
        }
    }
}

.chart-label {
    color: $mediumGrey;
}

.modal-ttl {
    margin-bottom: 0;
    margin-top: 30px;
    padding: 0 40px;

    @include media-breakpoint-down(xs) {
        margin-top: 0;
        padding: 0;
        text-align: center;
    }
}

.btn-modal {

    @include media-breakpoint-down(xs) {
        width: 100%;
    }

    & + .btn-modal {

        @include media-breakpoint-down(xs) {
            margin-top: 15px;
        }
    }
}

.modal {

    .modal-icon {
        font-size: 36px;
    }
}



