.company-block {
    height: 100%;
    padding: 15px 20px;
    border: 1px solid $lightGrey;
    border-radius: 4px;
    margin-top: 20px;
    max-height: 600px;

    ul {
        @include list-unstyled;
        overflow: auto;

        li {
            color: $darkGrey;

            & + li {
                margin-top: 5px;
            }
        }

    }
}

.suppliers-form {
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
