@use 'sass:math';

.mrc-mat-table {
    width: 100%;

    thead {
        > tr {
            > th {
                font-size: 1rem;
                padding-top: $table-cell-offset-y;
                padding-bottom: math.div($table-cell-offset-y, 2);
                white-space: nowrap;
            }
        }
    }
}

.table.mrc-table {
    margin-bottom: 0;

    th {
        @extend %heading;
    }

    td,
    th {
        padding: $table-cell-offset-y math.div($table-cell-offset-x, 2);
        border-top: none;
        transition: background-color $default-anmation,
            box-shadow $default-anmation;

        &:last-child {
            padding-right: $table-cell-offset-x;
        }

        &:first-child {
            padding-left: $table-cell-offset-x;
        }
    }

    tbody {
        > tr {
            > td,
            > th {
                padding: $table-cell-offset-y math.div($table-cell-offset-x, 2);
                border-top: none;
                cursor: pointer;

                &:last-child {
                    padding-right: $table-cell-offset-x;
                }

                &:first-child {
                    padding-left: $table-cell-offset-x;
                }
            }

            &:hover {
                > td,
                > th {
                    background-color: $table-row-color-hover;
                }
            }

            &.new {
                > td,
                > th {
                    @extend %heading;

                    &:first-child {
                        box-shadow: inset 2px 0 0 0 $color-primary;
                    }
                }
            }
        }
    }

    thead {
        > tr {
            > th {
                padding-top: $table-cell-offset-y;
                padding-bottom: math.div($table-cell-offset-y, 2);
                border-top: 1px solid $table-border-color;
                white-space: nowrap;
            }
        }
    }

    .table-head-text {
        white-space: nowrap;
    }
}

%mrc-header-cell {
    @include media-breakpoint-down(sm) {
        padding: 0 0 14px;
    }
}

.mrc-table-header {
    height: 50px;
    margin-bottom: 8px;

    .cell-tools {
        @extend %mrc-header-cell;
        display: flex;
        align-items: center;
    }

    .cell-tabs {
        @extend %mrc-header-cell;
        width: 100%;

        .ng-select {
            width: 170px;

            @include media-breakpoint-up(sm) {
                width: 240px;
            }
        }

        @include media-breakpoint-up(lg) {
            max-width: calc(100% - 320px);
        }
    }

    .cell-categories {
        @extend %mrc-header-cell;

        .ng-select {
            width: 170px;

            @include media-breakpoint-up(sm) {
                width: 240px;
            }
        }

        @include media-breakpoint-up(md) {
            max-width: 100%;
        }
    }

    .cell-filters {
        @extend %mrc-header-cell;

        .mat-tab-header {
            padding: 0;
            margin: 0 -10px -1px;
        }
    }
}

mrc-table,
.mrc-table {
    background: transparent;

    mrc-status-tabs .mat-tab-header {
        border-radius: 8px;
        background: #fff;
    }

    .mrc-datatable {
        margin-top: 15px;
        background: #fff;
        border: 1px solid #e5e5e5;
        box-sizing: border-box;
        border-radius: 8px;
    }
}

.cell-filters > .mat-tab-header {
    border-bottom: none !important;
    padding: 0 5px;

    .mat-tab-label {
        min-width: 0;
        padding: 0 20px;
        margin: 0 10px;
    }

    .mat-ripple-element {
        background-color: transparent;
        display: none !important;
    }
}

.ngx-datatable.mrc-datatable {
    font-size: $default-font-size;

    @include media-breakpoint-down(sm) {
        font-size: 14px;
    }

    .datatable-body-cell {
        &:hover {
            text-overflow: unset;
            width: auto;
        }

        .datatable-body-cell-label {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 13px;
            font-weight: 100;
            color: #8496ab;

            &:hover {
                text-overflow: unset;
                width: auto;
                position: absolute;
                background: #f2f5f7;
                z-index: 999;
                padding: 0 15px;
                margin: 0 -15px !important;
            }
        }
    }

    .prop {
        @include media-breakpoint-down(sm) {
            padding: 0 10px;
            white-space: normal;
        }
    }

    .datatable-body {
        min-height: 3px;

        .datatable-scroll {
            vertical-align: top;

            @include media-breakpoint-down(sm) {
                width: 100% !important;
            }

            .datatable-row-wrapper,
            .datatable-row-wrapper .datatable-body-row {
                height: 42px !important;
                padding-top: 0 !important;
                padding-bottom: 0 !important;
            }

            .datatable-row-wrapper .datatable-body-row .datatable-body-cell {
                height: 42px !important;
                padding-top: 0 !important;
                padding-bottom: 0 !important;

                .datatable-body-cell-label {
                    padding-top: 4px;
                }
            }
        }

        .datatable-body-row {
            padding: 0;
            border: none;
            background-color: transparent;
            border: none !important;
            border-bottom: 1px solid #f2f5f7 !important;

            @include transition(border 0.2s ease-out);

            @include even() {
                background-color: $table-row-even-bg;
            }

            &:hover {
                background-color: #f2f5f7;
            }

            @include media-breakpoint-down(sm) {
                width: 100% !important;
                flex-wrap: wrap !important;
                height: auto !important;
                border-radius: $border-radius;
                background-color: #fff;
                padding: 21px 0;
                margin: 16px 0 14px;
            }

            > div {
                @include media-breakpoint-down(sm) {
                    width: 100% !important;
                    flex-wrap: wrap !important;
                }
            }
        }

        .datatable-body-cell {
            padding: $table-cell-offset-y math.div($table-cell-offset-x, 2);
            letter-spacing: 1px;
            border-top: none;
            cursor: pointer;
            display: inline-flex;
            align-items: center;

            &.text-overflow-none,
            &.text-overflow-none .datatable-body-cell-label {
                text-overflow: clip;
                overflow: hidden;
            }

            &:last-child {
                padding-right: $table-cell-offset-x;
            }

            &:first-child {
                padding-left: $table-cell-offset-x;
            }

            .table-btn {
                height: 32px;
                min-width: 90px;
                font-size: 12px;
            }

            .table-icon {
                position: relative;
                top: 8px;
                margin-right: 15px;
                width: 24px;
            }

            .btn-table-icon {
                background: transparent;
                position: relative;
                margin-right: 15px;
                cursor: pointer;
                border: none;
                width: 24px;
                padding: 0;
                &:hover {
                    color: #6a798c !important;
                }

                &:disabled {
                    color: #babdc2 !important;
                }
            }

            &.cell-right {
                display: flex;
                justify-content: flex-end;
            }

            @include media-breakpoint-down(sm) {
                width: 100% !important;
                border: none;
                line-height: 18px;

                &.cell-view {
                    a,
                    button {
                        margin-top: 12px;
                    }
                }

                &,
                &:last-child,
                &:first-child {
                    padding: 8px 10px;
                }

                &:nth-child(1),
                &:nth-child(2) {
                    width: auto !important;
                    min-width: 40%;

                    .prop {
                        .name {
                            font-size: inherit;
                            display: inline;

                            &:after {
                                content: ': ';
                            }
                        }
                    }
                }

                &.w-50-mobile {
                    width: 50% !important;
                }

                .prop {
                    .name {
                        font-size: 12px;
                    }

                    .value {
                        font-weight: 500;
                        color: $darkGrey;
                    }
                }
            }
        }
    }

    .datatable-header {
        @include media-breakpoint-down(sm) {
            display: none !important;
        }

        .datatable-body-row {
            padding: 0;
            border: none;
        }

        .datatable-header-cell {
            padding: $table-cell-offset-y math.div($table-cell-offset-x, 2)
                $table-cell-offset-y;
            border-bottom: 1px solid #f2f5f7;
            white-space: nowrap;
            font-weight: 300;
            font-size: 12px;
            color: #4f5862;

            @extend %heading;

            &:last-child {
                padding-right: $table-cell-offset-x;
            }

            &:first-child {
                padding-left: $table-cell-offset-x;
            }

            &:not(.sortable) {
                .sort-btn {
                    display: none !important;
                }
            }

            .sort-btn {
                display: inline-block;
                margin-left: 5px;

                &:after,
                &:before {
                    display: block;
                    content: '';
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: $sort-height math.div($sort-width, 2)
                        $sort-height math.div($sort-width, 2);
                    border-left-color: transparent !important;
                    border-right-color: transparent !important;
                }

                &:before {
                    border-bottom-color: #4f5862;
                    border-top: 0;
                    margin-bottom: 2px;
                }

                &.sort-asc {
                    &:before {
                        border-color: #4f5862;
                    }
                }

                &:after {
                    border-top-color: #4f5862;
                    border-bottom: 0;
                }

                &.sort-desc {
                    &:after {
                        border-color: #8496ab;
                    }
                }
            }
        }
    }

    .datatable-footer {
        background-color: transparent;

        .datatable-footer-inner {
            justify-content: center;

            .ng-star-inserted {
                width: 100%;
                margin: 38.5px 0;
            }
        }

        .datatable-pager {
            text-align: center;
            padding: 38.5px 0;

            ul {
                @extend %clear-list;
                @extend %flex-center;

                > li {
                    @extend %flex-center;
                    margin: 1.5px;
                    min-width: $pagination-cell-size;
                    height: $pagination-cell-size;
                    border-radius: $pagination-cell-radius;
                    border: 1px solid rgba($pagination-cell-color, 0.5);
                    color: $pagination-cell-color;
                    font-size: 14px;
                    transition: color $default-anmation,
                        border-color $default-anmation;
                    padding: 0;

                    //hidden go to first/last page
                    &:first-child,
                    &:last-child {
                        display: none;
                    }

                    &:hover {
                        border-color: $pagination-cell-color;
                    }

                    &.active {
                        background: transparent;
                        color: $pagination-cell-color-active;
                        border-color: $pagination-cell-color-active;
                    }

                    &.disabled {
                        display: none;
                    }

                    > a {
                        @extend %flex-center;
                        @extend %full-width;
                        background-color: transparent;
                        color: inherit;
                        text-decoration: none;
                        border-radius: $pagination-cell-radius;
                        transition: color $default-anmation,
                            border-color $default-anmation;

                        i {
                            display: flex;
                            font-style: normal;
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }

    .progress-linear {
        display: block;
        width: 100%;
        height: 3px;
        padding: 0;
        margin: 0;
        position: absolute;

        .container {
            display: block;
            position: relative;
            overflow: hidden;
            width: 100%;
            height: 100%;
            transform: translate(0, 0) scale(1, 1);
            background-color: rgba($color-primary, 0.3);

            .bar {
                animation: query 1.2s infinite
                    cubic-bezier(0.39, 0.575, 0.565, 1);
                transition: transform 0.2s linear;
                background-color: $color-primary;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}

/**
 * Progress bar animations
 */
@keyframes query {
    0% {
        opacity: 1;
        transform: translateX(35%) scale(0.5, 1);
    }

    100% {
        opacity: 0;
        transform: translateX(-50%) scale(0, 1);
    }
}

.sort {
    display: inline-block;
    margin-left: 5px;

    a {
        text-decoration: none;
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: $sort-height math.div($sort-width, 2) $sort-height
            math.div($sort-width, 2);
        border-left-color: transparent !important;
        border-right-color: transparent !important;

        &:hover,
        &.active {
            border-color: $sort-color-active;
        }
    }

    .up {
        border-bottom-color: $sort-color;
        border-top: 0;
        margin-bottom: 4px;
    }

    .down {
        border-top-color: $sort-color;
        border-bottom: 0;
    }
}

.status-indicator {
    display: flex;
    align-items: center;

    .ico {
        font-size: 12px;
        margin-right: 5px;
    }
}

.pager {
    display: flex;
    align-items: center;
    margin: 0 20px;
    color: $lightBlue;
    float: right;
    margin-right: 40px !important;

    .ng-select {
        margin: 0 2px;
    }

    > li {
        margin: 0 !important;
        border-top: 1px solid #e5e5e5 !important;
        border-bottom: 1px solid #e5e5e5 !important;
        border-right: 0 !important;
        border-left: 1px solid #e5e5e5 !important;
        font-weight: 500;
        font-size: 12px !important;
        line-height: 16px;
        color: #8496ab !important;

        a {
            margin-bottom: -1px;
        }
    }

    > li.pages {
        border-radius: 0 !important;

        &.active {
            background: #fb5b5b !important;
            color: #ffffff !important;
            border: 1px solid #fb5b5b !important;
        }
    }

    > li.disabled {
        display: flex !important;
    }

    > li.disabled:first-child,
    li.disabled:last-child {
        display: none !important;
    }

    > li:not(.pages) {
        border-radius: 8px 0 0 8px !important;
    }

    > li.pages + li:not(.pages) {
        border-radius: 0 8px 8px 0 !important;
        border-right: 1px solid #e5e5e5 !important;
    }

    > li.disabled + li.pages:last-child {
        border-radius: 0 8px 8px 0 !important;
    }
}

.table-dashboard {
    margin-bottom: 0;

    td {
        width: 80%;
        padding: 5px 20px;

        &:first-child {
            color: $darkGrey;
        }
    }

    thead {
        td {
            font-weight: 500;
            color: $black;
        }
    }
}

.empty-row {
    text-align: center;
    padding: 60px 0;
    font-size: 30px;

    .empty-leading {
        font-size: 16px;

        img {
            margin: 0 auto 20px;
            display: block;
        }
    }
}

table.detail {
    width: 100%;
    letter-spacing: 1px;
    line-height: 1.625;

    thead tr {
        border-bottom: 1px solid $table-border-color;
    }

    tr {
        td,
        th {
            padding: 15px 10px;
            font-size: 13px;
            font-weight: 100;
            color: #8496ab;

            &.highlighted,
            &.strong {
                font-size: 13px !important;
                font-weight: 100 !important;
                color: #4f5862 !important;
            }
        }
    }

    tbody tr.table-row-no-background {
        background-color: transparent !important;
    }

    tbody tr:hover {
        background-color: #f2f5f7;
    }
}

mat-table {
    width: 100%;
    letter-spacing: 1px;

    mat-header-row {
        min-height: 45px;
        border-bottom: 1px solid $table-border-color;
    }

    .mat-header-cell {
        color: $lightBlack;
        line-height: 1.625;
        font-weight: 400;
        font-size: 1rem;
    }

    mat-cell {
        line-height: 1.625;
    }

    mat-row:nth-child(even) {
        background-color: $table-row-even-bg;
    }

    mat-row:hover {
        @include media-breakpoint-up(md) {
            background-color: $table-row-color-hover !important;
            border-left: 4px solid;
        }
    }
}

table.detail {
    &.without-header {
        border-top: 1px solid $table-border-color;
    }

    thead th {
        font-weight: 400;
    }

    tr {
        display: flex;
        justify-content: space-between;
    }

    th,
    td:nth-child(2n) {
        color: $mediumGrey;
    }
}

.normal-cursor {
    cursor: default !important;
}
