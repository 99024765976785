.list-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 25px;

    i {
        margin-left: 5px;
    }

    td,
    th {
        padding: 10px;
        vertical-align: top;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    th {
        @extend %heading;
    }

    td {

    }
}