@use 'sass:math';

.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
    top: initial !important;
}

.ng-select,
.ng-select.ng-select-single {
    outline: none;

    &.form-control {
        padding: 5px 0px 5px 5px;
    }

    &.ng-select-focused {
        border-color: $mediumGrey;
    }

    .ng-placeholder {
        color: $darkGrey !important;
    }

    .ng-arrow-wrapper {
        text-align: left;

        .ng-arrow {
            border-width: 0;
        }
    }

    .ng-select-container {
        height: auto !important;
        background: #ffffff;
        border: 0px solid #e5e5e5 !important;
        box-sizing: border-box;
        border-radius: 2px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #4f5862;

        &:hover {
            box-shadow: none;
        }

        .ng-dropdown-panel > .ng-dropdown-panel-items {
            border: 1px solid #e5e5e5;
        }
    }

    &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
        box-shadow: none;
    }

    &.ng-select-opened {
        border-color: $lightGrey;
    }

    &:not(.ng-select-opened) {
        .ng-arrow::before {
            font-family: 'Font Awesome 5 Pro';
            content: '';
            font-size: 12px;
            color: black;
        }
    }

    &.ng-select-opened {
        .ng-arrow::before {
            font-family: 'Font Awesome 5 Pro';
            content: '\f077';
            font-size: 12px;
            color: black;
        }
    }

    &.ng-select-opened &.opened {
        box-shadow: none;
        .ng-select-container {
            .ng-arrow {
                border: none;
            }
        }
    }

    &.disabled {
        .ng-arrow {
            display: none;
        }
    }

    .form-control.ng-select-container {
        .ng-value-container {
            padding: 0;

            .ng-input {
                top: 0;
                left: 0;
                padding-left: 0;
                padding-right: 0;
                top: 0;
                height: 100%;
                @extend .form-control;
                border: none !important;
                background-color: transparent !important;

                input {
                    height: 24px;
                    padding: 0;
                    width: 100%;
                    display: block;
                }
            }
        }
    }

    .ng-dropdown-panel {
        background: #ffffff;
        border: 1px solid #e5e5e5;
        box-sizing: border-box;
        border-radius: 2px;

        .ng-dropdown-panel-items {
            .ng-option {
                padding: 9px 10px;
                font-size: 12px !important;
                line-height: 16px !important;
                color: #4f5862;
                border-bottom: 1px solid #e5e5e5 !important;

                &:last-child {
                    border-bottom: none !important;
                }

                &.ng-option-marked,
                &.ng-option-selected {
                    background: #f2f5f7;
                    font-weight: 300;

                    .ng-option-label {
                        font-weight: 300;
                    }
                }
            }
        }
    }
}

.ng-select:not(.form-control),
.ng-select.ng-select-single:not(.form-control) {
    .ng-arrow-wrapper {
        right: $form-control-offset-x;
    }
}

//Select xs
.ng-select.ng-select-xs {
    .ng-select-container {
        @extend .form-control-xs;

        .ng-value-container {
            .ng-input {
                @extend .form-control-xs;
            }
        }
    }

    .ng-dropdown-panel {
        .ng-dropdown-panel-items {
            .ng-option {
                padding: 3px $form-control-xs-offset-x;
                font-size: $form-control-xs-size;
            }
        }
    }

    .ng-arrow-wrapper {
        right: $form-control-xs-offset-x;
    }
}

.ng-dropdown-panel {
    box-shadow: none;

    &.bottom {
        border-radius: 0 0 4px 4px;
    }

    &.top {
        border-radius: 4px 4px 0 0;
    }

    .ng-dropdown-panel-items {
        margin: 0;

        .ng-option {
            padding: 9px 20px;
            line-height: 20px;

            &.marked {
                background-color: $lightGrey;
                color: $color-primary;
            }

            &.selected {
                .ng-option-label {
                    font-weight: 300 !important;
                }
            }
        }
    }
}

.ng-select.ng-select-multi {
    &.ng-select-filtered {
        .ng-placeholder {
            display: none !important;
        }
    }

    .ng-value {
        display: none;
    }

    .ng-placeholder {
        display: block !important;
        padding-left: 0 !important;
        top: 6px !important;
    }

    .ng-value-container {
        padding: 0 !important;
    }

    .ng-select-container {
        padding-right: 5px !important;
    }

    .ng-input {
        padding-left: 0 !important;
    }
}

.multiple-list {
    ul {
        @include list-unstyled;

        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 5px;
        }
    }
}

form {
    .mat-radio-button {
        color: $default-text-color;

        &.mat-accent {
            font-family: inherit;
            color: $default-text-color;
        }

        .mat-radio-container {
            width: $radio-btn-size;
            height: $radio-btn-size;
        }

        .mat-radio-outer-circle {
            width: $radio-btn-size;
            height: $radio-btn-size;
            border-width: 1px;
            border-color: $mediumGrey;
        }

        .mat-radio-inner-circle {
            width: $radio-btn-size;
            height: $radio-btn-size;
        }

        .mat-radio-label {
            margin: 0;
            font-size: 14px;
            line-height: math.div(24, 12);

            &:hover {
                color: $darkGrey;
            }
        }

        &.mat-accent.mat-radio-checked {
            .mat-radio-label {
                color: $darkGrey;
            }

            .mat-radio-outer-circle {
                /*border-color: $color-primary !important;
                border-width: 4px;*/
                border: 4px solid #fb5b5b !important;
                border-color: #fb5b5b !important;
            }

            .mat-radio-inner-circle {
                height: 0;
                width: 0;
            }
        }
    }
}

.mat-slide-toggle.mat-accent {
    height: auto;
    position: relative;
    max-height: 32px;

    &.mat-checked {
        .mat-slide-toggle-thumb-container {
            transform: translate3d(
                $switcher-track-width - $swither-toggle-width -
                    $switcher-track-offset,
                0,
                0
            );
        }

        &:not(.mat-disabled) {
            .mat-slide-toggle-bar {
                background-color: #fb5b5b !important;
            }

            .mat-slide-toggle-thumb-container {
                .mat-slide-toggle-thumb {
                    background-color: $swither-toggle-bg;
                }
            }
        }

        &.mat-disabled {
            .mat-slide-toggle-bar {
                background-color: lighten($switcher-track-bg-active, 20%);
            }
        }
    }

    .mat-slide-toggle-bar {
        width: $switcher-track-width;
        height: $switcher-track-height;
        background-color: $switcher-track-bg;
        border-radius: $switcher-track-radius;
    }

    .mat-slide-toggle-thumb-container {
        width: $swither-toggle-width;
        height: $swither-toggle-height;
        top: $switcher-track-offset;
        transform: translate3d($switcher-track-offset, 0, 0);

        .mat-slide-toggle-thumb {
            width: 100%;
            height: 100%;
            box-shadow: none;
            background-color: $swither-toggle-bg;
        }
    }

    .mat-slide-toggle-label {
        margin: 0;
    }

    .mat-slide-toggle-content {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        letter-spacing: 0;
        padding: 0 9px;
        font: 700 10px/1.25 $default-font;

        &:after,
        &:before {
            display: block;
            color: #fff;
            text-transform: uppercase;
        }

        &:after {
            content: 'aus';
        }

        &:before {
            content: 'an';
        }
    }
}

.form-control {
    font-size: 0.85rem;
    font-weight: 400;
    height: 45px;
    color: #4f5862;
    border: 1pt solid $lightGrey;
    background-color: #fff;
    border-radius: 2px;
}

.form-control::placeholder,
input::placeholder {
    font-family: Avenir;
    font-style: normal;
    font-weight: 100;
    font-size: 12px;
    display: flex;
    align-items: center;
    text-indent: 10px;
    color: #999;
}

.input-group-append .btn {
    line-height: 1.7;
}

input:disabled.form-control {
    background-color: $lighterGrey;
    border: none;
}

.form-group {
    margin: $form-group-offset-y 0;

    i.info {
        color: $lightBlack;
    }

    label {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #4f5862;
    }

    textarea:disabled.form-control {
        border: none;
        background-color: $lighterGrey;
    }

    mrc-head-content & {
        margin: 0;
    }

    &.form-group-mt {
        margin-top: -25px;
    }

    &.form-group-color {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .form-control-holder {
            display: flex;
            align-items: center;

            input {
                @include circle(40px);
                border: none;
            }

            .color-prefix {
                font-size: em(12);
                margin-right: 10px;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

textarea {
    &.form-control {
        min-height: 200px;
        padding: 12px 15px;
    }
}

.upload-block {
    background-color: rgba(230, 230, 230, 0.2);
    border: dashed 1pt rgba(118, 118, 118, 0.33);
    border-radius: 3px;
    text-align: center;
    display: block;
    padding: 34px 0 27px;
    font-size: em(14);
    color: $mediumGrey;
    text-decoration: underline;

    i {
        font-size: em(38);
        color: $lightGrey;
    }
}

.form-group-inline {
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
        margin: 0 20px 0 0;
    }

    .form-control-holder {
        max-width: 120px;
    }
}

.form-group-title {
    @extend .h3;
    margin: 0;

    i {
        vertical-align: middle;
    }
}

.form-group-btn {
    margin: 30px 0;
}

.form-group-horizontal {
    margin: 18px 0;
}

.form-tooltip {
    position: absolute;
    @extend %form-tooltip-bottom;
    font-size: 14px;
    line-height: 16px;
    padding: 10px 14px;
    background-color: $form-tooltip-bg;
    color: #fff;
    border-radius: 1px;
    transition: none;
    z-index: 100;
    visibility: hidden;
    pointer-events: none;
    max-width: 100%;

    @include media-breakpoint-up(sm) {
        white-space: nowrap;
        max-width: none;
    }

    &.form-tooltip-left {
        min-width: 180px;
    }

    &.show {
        transition: opacity 0.3s ease;
        visibility: visible;
    }

    &:after {
        content: '';
        position: absolute;
        display: block;
        border-style: solid;
    }
}

%form-tooltip-bottom {
    left: 0;
    top: 100%;
    transform: none;
    margin: 10px 0 0 0;

    &:after {
        border-width: 0 $form-tooltip-arr-size $form-tooltip-arr-size;
        border-color: transparent transparent $form-tooltip-bg;
        left: 10px;
        bottom: 100%;
        transform: none;
    }
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .form-tooltip#{$infix}-right {
            left: 100%;
            top: 50%;
            right: auto;
            bottom: auto;
            transform: translate(0, -50%);
            margin: 0 0 0 $form-tooltip-offset;

            &:after {
                border-width: $form-tooltip-arr-size $form-tooltip-arr-size
                    $form-tooltip-arr-size 0;
                border-color: transparent $form-tooltip-bg transparent
                    transparent;
                right: 100%;
                top: 50%;
                left: auto;
                bottom: auto;
                transform: translateY(-50%);
            }
        }

        .form-tooltip#{$infix}-left {
            right: 100%;
            left: auto;
            top: 50%;
            bottom: auto;
            transform: translate(0, -50%);
            margin: 0 $form-tooltip-offset 0 0;

            &:after {
                border-width: $form-tooltip-arr-size 0 $form-tooltip-arr-size
                    $form-tooltip-arr-size;
                border-color: transparent transparent transparent
                    $form-tooltip-bg;
                left: 100%;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .form-tooltip#{$infix}-bottom {
            left: 0;
            right: auto;
            top: 100%;
            bottom: auto;
            transform: none;
            margin: 10px 0 0 0;

            &:after {
                border-width: 0 $form-tooltip-arr-size $form-tooltip-arr-size;
                border-color: transparent transparent $form-tooltip-bg;
                left: 20px;
                bottom: 100%;
                transform: none;
            }
        }

        .form-tooltip#{$infix}-top {
            left: 0;
            right: auto;
            bottom: 100%;
            top: auto;
            transform: none;
            margin: 0 0 10px 0;

            &:after {
                border-width: $form-tooltip-arr-size $form-tooltip-arr-size 0;
                border-color: $form-tooltip-bg transparent transparent;
                left: 10px;
                top: 100%;
                transform: none;
            }
        }
    }
}

.form-horizontal-flex {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .form-group {
        margin: 0;
    }

    button {
        padding: 0.5em 40px;
    }
}

.form-table {
    .form-group {
        margin: 15px 0;
    }
}

.datepicker-input {
    position: absolute;
    bottom: 0;
    width: 100%;
    visibility: hidden;
}

.portal-select {
    min-width: 200px;

    .ng-select-container {
        border: none !important;
    }
}

.inline-form-group {
    .form-group {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.percent-icon {
    font-weight: 700;
    font-size: 20px;
}

.checkbox-sm {
    label {
        font-size: 14px;
        color: $mediumGrey;
        font-family: $ubuntu;

        .mat-checkbox-label {
            line-height: 15px;
        }
    }

    .mat-checkbox-inner-container {
        height: 15px;
        width: 15px;
    }

    .mat-checkbox-background,
    .mat-checkbox-frame {
        border-radius: 3px;
    }
}

mat-checkbox {
    .mat-checkbox-layout {
        .mat-checkbox-label {
            font-size: 0.85rem;
            color: $color-heading;
            font-family: $ubuntu;
            line-height: 17px;
            white-space: normal;
            font-weight: 400;
            margin-left: 2px;
        }
    }

    .mat-checkbox-frame {
        border-width: 1px;
    }
}

.mat-to .pdf-link {
    text-decoration: none;
    color: $mediumGrey;
}
