.settings-btn {
    background: none;
    color: $darkGrey;
    @extend %clear-button;
    cursor: pointer;
    outline: none !important;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $white;
    line-height: 1;
    padding: 10px 10px 10px 20px;
    border-bottom: 1px solid $mediumGrey;

    .card-title {
        margin-bottom: 5px;
    }

    @include media-breakpoint-down(xs) {
        padding: 10px;
    }

    .card-label {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        font-weight: 500;
        white-space: nowrap;

        @include media-breakpoint-down(xs) {
            display: block;
        }

        .ng-select {
            min-width: 300px;

            @include media-breakpoint-down(xs) {
                margin-top: 5px;
                min-width: 250px;
            }

            .ng-value-container {

                @include media-breakpoint-down(xs) {
                    font-size: 11px;
                    line-height: 14px;
                }
            }

            .ng-option {

                @include media-breakpoint-down(xs) {
                    font-size: 12px !important;
                    line-height: 14px;
                }
            }
        }


    }

    .card-icon {
        color: $lightGrey;
    }
}

.card-title {
    margin-bottom: 4px;
}

.card-body {
    padding: 27px 32px 32px 33px;

    .card-table-wrap {
        max-height: 200px;
        overflow-y: auto;
    }

    .data-row {
        display: flex;
        margin-top: 35px;

        .label {
            margin-right: 80px;
            font-weight: 400;
            }
        .value {
            color: $mediumGrey;
            font-weight: 500;
        }
    }
}

.chart-wrap {
    padding: 30px;
    height: 350px;
    position: relative;

    @include media-breakpoint-down(xs) {
        height: 250px;
        padding: 10px 5px;
    }
}

.card {
    border: 1px solid #E5E5E5;
    margin-bottom: 30px;
    border-radius:8px;

    &.equal {
        margin-bottom: 0;
        height: 100%;
    }

    & > .preloader {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba($white, .8);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;

        i {
            font-size: 70px;
        }
    }

    div {
        .row {

            &.adjust-half-row {
                margin: -12px -15px;
            }

            .column {
                padding-right: 30px;
            }
        }

    }
}

.col-equal {
    padding-bottom: 30px;
}
