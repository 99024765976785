.mrc-pagination {
    margin: 40px 0;

    .ngx-pagination {
        color: #aeb8c6;
        float: right;
        margin-right: 40px !important;
        display: inline-flex;

        li {
            margin: 0 !important;
            border-top: 1px solid #E5E5E5 !important;
            border-bottom: 1px solid #E5E5E5 !important;
            border-right: 0 !important;
            border-left: 1px solid #E5E5E5 !important;
            font-weight: 500;
            font-size: 12px !important;
            line-height: 1;
            color: #8496AB !important;
            border-radius: 0 !important;
            width: 38px;
            height:38px;
            text-align: center;

            &.pagination-previous {
                display:flex!important;
                border-radius: 8px 0 0 8px !important;
                font-size: 0 !important;

                a:after {
                    content: '\6f'!important;
                    font-family: 'data-table' !important;
                    font-style: normal !important;
                    font-weight: normal !important;
                    font-variant: normal !important;
                    text-transform: none !important;
                    font-size: 20px !important;
                    color: #8496AB !important;
                    line-height: 30px!important;
                    margin-right: 0!important;
                }
            }
            &.pagination-previous.disabled {
                display:flex!important;
                border-radius: 8px 0 0 8px !important;
                font-size: 0 !important;

                &:before {
                    content: '\6f'!important;
                    font-family: 'data-table' !important;
                    font-style: normal !important;
                    font-weight: normal !important;
                    font-variant: normal !important;
                    text-transform: none !important;
                    font-size: 20px !important;
                    color: #8496AB !important;
                    line-height: 30px!important;
                    margin-right: 0!important;
                }
            }

            &.pagination-next {
                display:flex!important;
                border-right: 1px solid #E5E5E5 !important;
                border-radius: 0 8px 8px 0!important;
                font-size: 0 !important;

                a:after {
                    content: '\6p'!important;
                    font-family: 'data-table' !important;
                    font-style: normal !important;
                    font-weight: normal !important;
                    font-variant: normal !important;
                    text-transform: none !important;
                    font-size: 20px !important;
                    color: #8496AB !important;
                    line-height: 30px!important;
                    margin-left: 0!important;
                }
            }

            &.pagination-next.disabled {
                display:flex!important;
                border-right: 1px solid #E5E5E5 !important;
                border-radius: 0 8px 8px 0!important;
                font-size: 0 !important;

                :after {
                    content: '\6p'!important;
                    font-family: 'data-table' !important;
                    font-style: normal !important;
                    font-weight: normal !important;
                    font-variant: normal !important;
                    text-transform: none !important;
                    font-size: 20px !important;
                    color: #8496AB !important;
                    line-height: 30px!important;
                    margin-right: 0!important;
                }
            }

            &.current {
                background: #FB5B5B !important;
                color: #ffffff !important;
                border: 1px solid #FB5B5B !important;
            }

            span {
                line-height:30px!important;
            }

            a {
                color: #8496AB !important;

                &:hover {
                    background:none;
                }
            }
        }
    }
}
