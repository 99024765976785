.show-pdf-modal {
    &__pdf-document {
        width: 100%;
        min-height: 500px;
    }

    &__loading {
        font-size: 24px;
        text-align: center;
        width: 100%;
    }
}
